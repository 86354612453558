const initialState = {
  properties: {},
  expirationsUG: {},
  goalsData:{},
  referenceRent: [],
  expiringLeases: [],
  thresholds: [],
  trends: [],
  preLeases:[],
  referenceHistory: [],
  expirationsRange: {},
  renewalOutlook: {},
  expiringUnits: {},
  revenue: [],
  recentLeasingAverage: [],
  brains: [],
  dailyLeads: {},
  dailyLeadsNew : {},
  leads: {},
  marketingScore:{},
  competitors: {},
  competitorsByProp: {},
  leaseCompliance: {},
  competitorsHistory: {},
  reports: {},
  newLeases: {},
  associatedProperty:"No",
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROPERTIES":
      return {
        ...state,
        properties: action.payload.properties,
      };
    case "GET_EXPIRATIONSUG":
      return {
        ...state,
        expirationsUG: action.payload.expirationsUG,
      };
      case "GET_GOALS_DATA":
        return {
          ...state,
          goalsData: action.payload.goalsData,
        };
    case "GET_REFERENCE_RENT":
      return {
        ...state,
        referenceRent: action.payload.referenceRent,
      };
    case "GET_EXPIRING_LEASES":
      return {
        ...state,
        expiringLeases: action.payload.expiringLeases,
      };
    case "GET_EXPIRATIONS_THRESHOLDS":
      return {
        ...state,
        thresholds: action.payload.thresholds,
      };
    case "GET_TRENDS":
      return {
        ...state,
        trends: action.payload.trends,
      };
    case "GET_PRE_LEASES_DATA":
        return {
          ...state,
          preLeases: action.payload.preLeases,
        };
    case "GET_REFERENCE_HISTORY":
      return {
        ...state,
        referenceHistory: action.payload.referenceHistory,
      };
    case "GET_RENEWAL_OUTLOOK":
        return {
          ...state,
          renewalOutlook: action.payload.renewalOutlook,
        };
    case "GET_EXPIRATIONS_RANGE":
      return {
        ...state,
        expirationsRange: action.payload.expirationsRange,
      };
    case "GET_EXPIRING_UNITS":
      return {
        ...state,
        expiringUnits: action.payload.expiringUnits,
      };
    case "GET_REVENUE":
      return {
        ...state,
        revenue: action.payload.revenue,
      };
      case "GET_RECENT_LEASING_AVERAGE":
        return {
          ...state,
          recentLeasingAverage: action.payload.recentLeasingAverage,
        };
    case "GET_BRAINS":
      return {
        ...state,
        brains: action.payload.brains,
      };

    case "GET_COMPETITORS_HISTORY":
      return {
        ...state,
        competitorsHistory: action.payload.competitorsHistory,
      };
    case "GET_DAILY_LEADS_BY_ID":
      return {
        ...state,
        dailyLeads: action.payload.dailyLeads,
      };
    case "GET_NEW_LEASES":
      return {
        ...state,
        newLeases: action.payload.newLeases,
      };
    case "GET_LEADS_BY_ID":
      return {
        ...state,
        leads: action.payload.leads,
      };
    case "GET_MARKETING_SCORE_BY_ID":
      return {
        ...state,
        marketingScore: action.payload.marketingScore,
      };      
    case "GET_COMPETITOR_STATS_BY_ID":
      return {
        ...state,
        competitors: action.payload.competitors,
      };
      case "GET_COMPETITOR_STATS_BY_PROP":
        return {
          ...state,
          competitorsByProp: action.payload.competitorsByProp,
        };
    case "GET_LEASE_COMPLIANCE_BY_ID":
      return {
        ...state,
        leaseCompliance: action.payload.leaseCompliance,
      };
    case "GET_OCCUPANCY_REPORT_BY_ID":
      state.reports[action.payload.type[action.payload.propertyId]] =
        action.payload.occupancyReport;
      return {
        ...state,
        occupancyReport:
          state.reports[action.payload.type[action.payload.propertyId]],
      };
    case "UPDATE_REFRENCE_RENT":
      state.referenceRent[action.payload.name][action.payload.unitType]['reference_rent'] = action.payload.todayVal
      return {
        ...state,
        referenceRent: state.referenceRent
      };
    case "UPDATE_PARAMETERS":
      state.brains[action.payload.propertyName] = action.payload.params
      return {
        ...state,
        brains: state.brains
      };
    case "UPDATE_ASSOCIATED_PROP":
      return {
        ...state,
        associatedProperty: action.payload.associatedProperty
      };     
    default:
      return {
        ...state,
      };
  }
};

export default propertyReducer;
