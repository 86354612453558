import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
// import { Spinner } from "react-bootstrap";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Switch, Space } from 'antd';

export default function CompetitorChart({ competitors }) {

  const [prevDays, setPrevDays] = useState(30)
  const [toggleEffectiveRent, setToggleEffectiveRent] = useState(false)
  const [data, setData] = useState(
    {
      labels: [...competitors.labels].splice(Math.max(competitors.labels - 30,0),competitors.labels),
      datasets: [
        {
          label: "Competitor",
          data: [...competitors.comp_psf].splice(Math.max(competitors.comp_psf.length-30,0),competitors.comp_psf.length),
          borderColor: "rgba(9, 151, 242, 1)",
          backgroundColor: "rgba(9, 151, 242, 1)",
          maxBarThickness: 15,
          borderRadius: 15,
          categoryPercentage: 0.7,
        },
        {
          label: "Site",
          data: [...competitors.psf].splice(Math.max(competitors.psf.length - 30),competitors.psf.length),
          borderColor: "rgba(255, 176, 103, 1)",
          backgroundColor: "rgba(255, 176, 103, 1)",
          maxBarThickness: 15,
          borderRadius: 15,
        },
      ],
    }
  )
  

  const setSegmented_ = (e) => {
    let gt = new Date() //the dates has to be greater than gt
    gt.setDate(gt.getDate() - parseInt(e))
    let dates = [...competitors.dates]
    dates.reverse()

    let idx=-1
    for (let i of dates){
      idx++
      let date = new Date(i)
      if (date < gt) break
      // console.log(date, idx)
    }

    console.log("IDX AND E", idx, e)

    let siteData = toggleEffectiveRent ? [...competitors.effective_rent] : [...competitors.psf]
    let compData = toggleEffectiveRent ? [...competitors.comp_effective_rent] : [...competitors.comp_psf]

    setData({
      labels:[...competitors.labels].reverse().splice(0, idx).reverse(),
      datasets:[
        {
          label: "Competitor",
          data: compData.reverse().splice(0,idx).reverse(),
          borderColor: "rgba(9, 151, 242, 1)",
          backgroundColor: "rgba(9, 151, 242, 1)",
          maxBarThickness: 15,
          borderRadius: 15,
          categoryPercentage: 0.7,
        },
        {
          label: "Site",
          data: siteData.reverse().splice(0,idx).reverse(),
          borderColor: "rgba(255, 176, 103, 1)",
          backgroundColor: "rgba(255, 176, 103, 1)",
          maxBarThickness: 15,
          borderRadius: 15,
        },
      ]
    })
  }


  useEffect(()=>{
    setSegmented_(prevDays)
  },[prevDays,toggleEffectiveRent])


  const options = {
    elements: {
      line: {
        tension: 0.6,
      },
    },
    plugins: {
      legend: {
        display: true
      },
      tooltip: {
        position: "average",
      },
    },
    scaleShowLabels: false,
  };

  const handleSwitch = (value) => {
    setToggleEffectiveRent(value)
  }

  const handleChange = (event,newAlignment) => {
    setPrevDays(parseInt(newAlignment));
  };

  return (
    <div
      className="comp-chart bg-white w-75 mt-4 mb-2 py-3 px-4 shadow-sm border d-flex flex-column"
      style={{ borderRadius: "14px" }}
    >
      
      <div style=
          {{
            display:'flex',
            justifyContent:'space-between',
            alignItems:"center",
          }}>
            <h4 className="m-0 fw-bold mt-3">Competitor Chart</h4>
            
            <Space direction="vertical">
              <Switch checkedChildren="Effective Rent" unCheckedChildren="Effective Rent"  onChange={handleSwitch} />
            </Space>

            <ToggleButtonGroup
              color="primary"
              value={`${prevDays}`}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="30">30</ToggleButton>
              <ToggleButton value="60">60</ToggleButton>
              <ToggleButton value="120">120</ToggleButton>
              <ToggleButton value="365">365</ToggleButton>
            </ToggleButtonGroup>

          </div>
      {competitors.psf.length !== 0 ? (
        <>
          <Line
            options={options}
            width={65}
            height={30}
            data={data}
            className="my-auto"
          />
        </>
      ) : (
        <div className="d-flex w-100 h-100 justify-content-center">
          {/* <Spinner animation="border" role="status" className="my-5">
            <span className="visually-hidden">Loading...</span>
          </Spinner> */}
          <h4 className="mt-4"> Competitors Not Found. </h4>
        </div>
      )}
    </div>
  );
}
