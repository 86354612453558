import React, { useState, useEffect } from 'react';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import swal from 'sweetalert';
import Spinner from "../../Spinner";
import  axios  from "../../../auth/Axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const ParamsList = () => {
    const [formValues, setFormValues] = useState({});
    const [openSection, setOpenSection] = useState(null); // Track the open section
    const [propDict, setPropDict] = useState([{}])
    const [parameters, setParameters] = useState({})
    const [loading, setLoading] = useState(false);
    const [selectedProps, setSelectedProps] = useState([])
    const animatedComponents = makeAnimated();

    const fetchData = () => {
        axios.get("bulk-update-params")
        .then(res => {
            setParameters(res.data.params)
            setPropDict(res.data.prop_dict)
        })
        .catch(err => {
            setTimeout(() => {
                fetchData();
            }, 2000);

        })
    }

    useEffect(() => {
        fetchData();
    },[])

    const handleSelect = (val) => {
        setSelectedProps(val)
    }

    const handleInputChange = (e, param) => {
        let value = e.target.value;
        if ( Number(value) !== 0 && e.target.type === 'number') {
            value = parseFloat(value);
        }
        setFormValues(prevValues => ({
            ...prevValues,
            [param]: value
        }));
    };


    const isProperList = (input, type) => {
        const patternRentAutopush = /^\[\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*\]$/;
        const patternExposureOffsets = /^\[\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*\]$/;

        if (type === "rent_autopush_params")
            return patternRentAutopush.test(input);
        else return patternExposureOffsets.test(input);
    };

    const validateRentAutopushParams = (values) => {
        const newLeaseRentTerms = values.new_lease_rent_terms
        const renewalRentTerms = values.renewal_rent_terms

        if ((newLeaseRentTerms === undefined && renewalRentTerms === undefined) || (newLeaseRentTerms === "" && renewalRentTerms ==="")) {
            return false
        }

        if ((newLeaseRentTerms !== undefined && newLeaseRentTerms !== "") && isProperList(newLeaseRentTerms, "rent_autopush_params") === false) {
            swal("Error", "Please use a proper array of 2 numbers for the new lease rent terms. Ex:- [9,12]", "error")
            return false
        }

        if ((renewalRentTerms !== undefined && renewalRentTerms !== "") && isProperList(renewalRentTerms, "rent_autopush_params") === false) {
            swal("Error", "Please use a proper array of 2 numbers for the renewal rent terms. Ex:- [1,12]", "error")
            return false
        }
        return true

    }

    const handleSubmit = (paramName) => {
        setLoading(true)
        if (paramName === "rent_autopush_params"){
            const isValid = validateRentAutopushParams(formValues)
            if (!isValid){
                setLoading(false)
                return
            } 
        }

        if (paramName === "exposure_offsets") {
            if (!isProperList(formValues.exposure_offsets,paramName)) {
                swal("Error", "Please use a proper array of 3 numbers for the exposure offsets. Ex:- [0,0.36,0.4]", "error")
                setLoading(false)
                return
            }
        }

        if (selectedProps.length === 0) {
            swal("Error", "Please select a property.", "error")
            setLoading(false)
            return
        }

        const data = {
            paramName,
            selectedProps,
            formValues
        }
        axios.post("bulk-update-params", data)
        .then(res => {
            swal("Success", "Successfully Updated", "success")
            setLoading(false)
        })
        .catch(err=>{
            console.log("an error occured")
            setLoading(false)
            swal("Error", "An Unknown Error Occured.", "error")
        })
    };

    const toggleSection = (index) => {
        setOpenSection(openSection === index ? null : index); // Toggle the section open/close
        setFormValues({})
        setSelectedProps([])
    };

    const Capitalise =(word) => {
        if (typeof word === "string") {
            if (word === "refrence_rents"){
                return "Reference Rents"
            }
            return word.split("_").map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
        }
        else return word
    }

    const getPlaceHolder = (paramName) => {
        if (paramName === "rent_autopush_params") return "Ex:- [1,2]"
        else if (paramName === "exposure_offsets") return "Ex: [1,2,3]"
        else return ""
    }

    return (
        <div style={{
            display:"flex",
            flexDirection:"column",
            width:"100%"
        }}>
            <div style={{ margin:"50px 0px 0px 50px" }}>
                <h3 className="fw-bold mt-4">Bulk Update Params</h3>
            </div>

            {
                propDict.length === 0 || Object.keys(parameters).length === 0 ?
                (
                    <Spinner animation="border" role="status" className="my-5">
                        <h6 className="fw-bold mt-3">
                        Please wait while we load the Report Information...
                        </h6>
                        <span>Crunching some numbers :)</span>
                    </Spinner>
                )
                :
                <div>
                    {Object.entries(parameters).map((item, index) => (
                        <div key={index} style={{ width:"90%", marginLeft:"20px"}}>
                            <div className="m-4 mt-4 py-4 shadow border p-3" style={{ borderRadius: "14px", width:"100%" }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h5>{Capitalise(item[0])}</h5>
                                    <DownOutlined
                                        onClick={() => toggleSection(index)}
                                        style={{ fontSize: '18px', cursor: 'pointer', transform: openSection === index ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    />
                                </div>

                                {openSection === index && ( // Only show if this section is open
                                    <>
                                        <div style={{ display:"flex", flexDirection:"column", gap:"2rem" }}>
                                            <div className="form" style={{width:"100%"}}>
                                                <form>
                                                    <div style={{ 
                                                        display: "flex", 
                                                        flexWrap: "wrap", 
                                                        gap: "15px", 
                                                        maxWidth: "100%", 
                                                    }}>
                                                        {item[1].map(param => (
                                                            <div key={param} style={{ marginBottom: "10px" }}>
                                                                <label htmlFor={param} style={{ 
                                                                    textAlign: 'right',
                                                                    marginLeft:"10px"
                                                                }}>
                                                                    {param.replace(/_/g, " ")}:
                                                                </label>
                                                                <input
                                                                    type= {item[0] === "rent_autopush_params" || item[0] === "exposure_offsets" ? "text" : "number"}
                                                                    placeholder={getPlaceHolder(item[0])}
                                                                    id={param}
                                                                    name={param}
                                                                    value={formValues[param] || ""}
                                                                    onChange={(e) => handleInputChange(e, param)}
                                                                    style={{ padding: "5px", width: "100px", 
                                                                        border:"none", borderBottom: "2px solid black", borderRadius:"0px", outline:"none" }}
                                                                    onFocus={(e) => e.target.style.borderBottom = "2px solid black"} // Ensure the border remains the same color on focus
                                                                    onBlur={(e) => e.target.style.borderBottom = "2px solid black"}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </form>
                                            </div>
                                            <div style={{maxWidth: "60%"}}>
                                                <Select
                                                    style={{ borderRadius: "16px" }}
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    placeholder={"Select All Properties"}
                                                    isMulti
                                                    onChange={(val) => handleSelect(val)}
                                                    options={propDict}
                                                />
                                            </div>                                        
                                        </div>
                                        {
                                            loading? 
                                            <LoadingOutlined style={{ fontSize: '45px', fontWeight:"bold", color: '#ff69b4' }}/>
                                            :
                                            <button
                                                onClick={() => handleSubmit(item[0])}
                                                style={{
                                                    marginTop: '20px',
                                                    padding: '10px 20px',
                                                    fontSize: '16px',
                                                    backgroundColor: '#ff69b4',
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer',
                                                    }}
                                            > Submit </button>
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

export default ParamsList;
