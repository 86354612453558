/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../Spinner";
import Property from "./Property/Property";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import SearchBar from "../New Leases/SearchBar";
import "./dashboard.css";
import { 
  loadExpirationsUG, 
  loadExpiringLeases,
  loadExpirationsThresholds,
  loadTrends,
  loadGoalsData,
  loadPreLeases,
  loadMarketingScore,
  loadRenewalOutlookChart,
  loadCompetitorsStatsByProp
} from "../../Actions/propertyActions";


export default function Dashboard() {
  const [selectedProperties, setSelectedProperties] = useState([
    { value: "all", label: "All" },
  ]);
  const [selectedPropNames, setSelectedPropNames] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const propsRedux = useSelector((state) => state.propertyReducer.properties);
  const expirationsUG = useSelector((state) => state.propertyReducer.expirationsUG);
  const expiryLeases = useSelector((state) => state.propertyReducer.expiringLeases);
  const thresholds = useSelector((state) => state.propertyReducer.thresholds);
  const trends = useSelector((state) => state.propertyReducer.trends);
  const goalsData = useSelector((state) => state.propertyReducer.goalsData);
  const preLeases = useSelector((state) => state.propertyReducer.preLeases);
  const marketingScore = useSelector((state) => state.propertyReducer.marketingScore);
  const renewalOutlook = useSelector((state) => state.propertyReducer.renewalOutlook);
  const competitorsStatByProp = useSelector((state) => state.propertyReducer.competitorsByProp);
  const dispatch = useDispatch();
  
  const isEmpty = (variable) => {
    if (variable === null || variable === undefined){
      return true
    }
    if (Array.isArray(variable)) {
      return variable.length === 0;
    }
    if (typeof variable === 'object') {
      return Object.keys(variable).length === 0;
    }
  }

  const fetchData = () => {
    if (isEmpty(expirationsUG) ) { dispatch(loadExpirationsUG());}
    if (isEmpty(expiryLeases) ) dispatch(loadExpiringLeases());
    if (isEmpty(thresholds) ) dispatch(loadExpirationsThresholds());
    if (isEmpty(trends) ) dispatch(loadTrends());
    if (isEmpty(goalsData) ) dispatch(loadGoalsData());
    if (isEmpty(preLeases) ) dispatch(loadPreLeases());
    if (isEmpty(marketingScore) ) dispatch(loadMarketingScore(0));  // putting a dummy property id 0
    if (isEmpty(renewalOutlook) ) dispatch(loadRenewalOutlookChart());
    if (isEmpty(competitorsStatByProp) ) dispatch(loadCompetitorsStatsByProp());
  }

  useEffect(() => {
    fetchData();
  },[])


  const showProperties = (data) => {
    try {
      if (data || selectedProperties) {
        setLoading(true);
        const selectedPropNames = [];
        if (
          selectedProperties.length === 0 ||
          selectedProperties.some((prop) => prop.value === "all")
        ) {
          selectedPropNames.push(...Object.keys(data));
        } else {
          let final = selectedProperties.filter((prop) => prop.value !== "");
          final.forEach((prop) => {
            selectedPropNames.push(prop.label);
          });
        }
        setSelectedPropNames(selectedPropNames);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      fetch();
    }
  };
  useEffect(() => {
    showProperties(propsRedux);
  }, [selectedProperties, propsRedux]);

  return (
    <div className="dashboard mx-auto pt-5 mb-5">
      <div className="mx-4">
        <div>
          <h3 className="fw-bold mt-4">Overview</h3>
        </div>
        <div className="d-flex align-items-center justify-content-between">
        <SearchBar
          leaseProperties={Object.keys(propsRedux).sort((a, b) => {
            return a.localeCompare(b);
          })}
          setSelectedProperties={setSelectedProperties}
        />
        <PrimaryBtn onClick={fetch} text="Refresh" className="h-50 w-50" />
      </div>
        <div className="d-flex flex-column">
          {Object.keys(expirationsUG).length === 0 ||
          Object.keys(thresholds).length === 0 ||
          isLoading || selectedPropNames.length === 0
          ? (
            <Spinner animation="border" role="status" className="my-5">
              <h6 className="fw-bold mt-3">
                Please wait while we load the Dashboard...
              </h6>
              <span>Crunching some numbers :)</span>
            </Spinner>
          ) : (
            Object.entries(
              Object.fromEntries(Object.entries(propsRedux).sort())
            ).map((property, index) => {
              return selectedPropNames.includes(property[0]) &&
                <Property
                  key={index}
                  name={property[0]}
                  trends={trends[property[0]]}
                  propertyId={property[1].property_id}
                  expirations={expirationsUG[property[0]]}
                  thresholds={thresholds[property[0]]}
                  expiryLeases={expiryLeases[property[0]]}
                  propertyData={property[1]}
                  goalsData = {goalsData}
                  isStudentProp = {property[1].is_student_prop}
                  preLeases = {preLeases[property[0]]}
                  marketingScores = {marketingScore}
                  renewalOutlook = {renewalOutlook[property[0]]}
                  renewalOutlookLabels = {renewalOutlook["labels"]}
                  competitorStat = {competitorsStatByProp[property[0]]}
                />
            })
          )}
        </div>
      </div>
    </div>
  );
}
