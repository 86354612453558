import React, { useEffect, useState, useRef } from "react";
import LegendBtn from "../../Buttons/LegendBtn";
import LineChart from "../../LineChart";
import SolidPrimaryBtn from "../../Buttons/SolidPrimaryBtn";
import OutlinedPrimaryBtn from "../../Buttons/OutlinedPrimaryBtn";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import MUIDataTable from "mui-datatables";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from "../../../auth/Axios";
import { Switch, Space } from 'antd';
import { Line } from "react-chartjs-2";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../assets/info.svg";

function DecisionSupportTools({
  name,
  bed,
  bath,
  showMore,
  dailyLeads,
  dailyLeadsLabels,
  referenceHistoryData,
  newLeases,
}) {
  const ho_ref = useRef();
  const [currentIndex, setCurrent] = useState(0);
  const [formattedNewLeases, setFormattedNewLeases] = useState([]);
  const [segmentedValue, setSegmentedValue] = useState('30');
  const [predicted, setPredicted] = useState({});
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [predictionChartData, setPredictionChartData] = useState({
    data: [...referenceHistoryData].reverse().slice(0,30).reverse(),
    labels: [...dailyLeadsLabels].reverse().slice(0,30).reverse()
  })
  
  const chartData_30 = {
    labels: [...dailyLeadsLabels].reverse().slice(0,30).reverse(),
    title: "Historical Offered",
    subTitle: " Applications & Reference rent",
    datasets: [
      {
        label: "Reference Rent History",
        data: [...referenceHistoryData].reverse().slice(0,30).reverse(),
        fill: "origin",
        yAxisID: "A",
        backgroundColor: "rgb(9, 151, 242, 0.22)",
        borderColor: "rgb(9, 151, 242)",
        borderWidth: 3,
        smooth: true,
      },
      {
        label: "Applications", 
        data: [...dailyLeads].reverse().slice(0,30).reverse(),
        fill: "origin",
        yAxisID: "B",
        type: "bar", 
        backgroundColor: "rgb(55, 209, 89, 0.16)",
        borderColor: "rgb(55, 209, 89)",
        borderWidth: 2,
        smooth: true,
      },
    ],
  };

  const [chartData, setChartData] = useState(chartData_30)

  const formatNewLeases = (data) => {
    if (data) {
      const temp = [];
      data.forEach((item) => {
        const row = [];
        row.push(
          ...[
            item.unit_number,
            item.unit_type,
            item.application_date,
            item.lease_start_date,
            `$${item.rent}`,
            `$${item.previous_rent}`
          ]
        );
        temp.push(row);
      });
      return temp;
    }
  };


  const formatChartData = (segmentedValue) => {
    let prevDays = parseInt(segmentedValue)
    let labels = segmentedValue === '365' ?  [...dailyLeadsLabels] : [...dailyLeadsLabels].reverse().slice(0,prevDays).reverse()
    let refRentData = segmentedValue === '365' ? [...referenceHistoryData] : [...referenceHistoryData].reverse().slice(0,prevDays).reverse()
    let leadsData = segmentedValue === '365' ? [...dailyLeads] : [...dailyLeads].reverse().slice(0,prevDays).reverse()

    setChartData(
      {
        labels: labels,
        title: "Historical Offered",
        subTitle: " Applications & Reference rent",
        datasets: [
          {
            label: "Reference Rent History",
            data: refRentData,
            fill: "origin",
            yAxisID: "A",
            backgroundColor: "rgb(9, 151, 242, 0.22)",
            borderColor: "rgb(9, 151, 242)",
            borderWidth: 3,
            smooth: true,
          },
          {
            label: "Applications",
            data: leadsData,
            fill: "origin",
            yAxisID: "B",
            type: "bar",
            backgroundColor: "rgb(55, 209, 89, 0.16)",
            borderColor: "rgb(55, 209, 89)",
            borderWidth: 2,
            smooth: true,
          },
        ],
      }
    )
  }

  useEffect(() => {
    setFormattedNewLeases(formatNewLeases(newLeases));
  }, [newLeases]);


  useEffect(() => {
    formatChartData(segmentedValue)
  }, [segmentedValue])


  const historicalOptions = {
    elements: {
      line: {
        tension: 0.4,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      A: {
        id: "A",
        type: "linear",
        position: "left",
        display: true,
        grid: {
          display: false,
        },
      },
      B: {
        id: "B",
        type: "linear",
        display: true,
        position: "right",
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: "average",
      },
    },
  };

  const columns = [
    "Unit Number",
    "Unit Type",
    "Application Date",
    "Lease Start Date",
    "Rent Amount",
    "Previous Rent"
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none",
    rowsPerPage: 5,
    elevation: 0,
    rowsPerPageOptions: [5, 10, 15, 20, 50],
  };
  const graphsData = [
    chartData_30,
    {
      title: "Recent Lease",
      table: (
        <div className="px-5">
          <ThemeProvider theme={createTheme()}>
            <MUIDataTable
              title="Recent Leases"
              data={formattedNewLeases}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>
      ),
    },
  ];
  const length = graphsData.length;

  const nextSlide = () => {
    setCurrent(currentIndex === length - 1 ? 0 : currentIndex + 1);
  };

  const prevSlide = () => {
    setCurrent(currentIndex === 0 ? length - 1 : currentIndex - 1);
  };

  if (!Array.isArray(graphsData) || graphsData.length <= 0) {
    return null;
  }

  const handleChange = (event,newAlignment) => {
    setSegmentedValue(newAlignment);
  };

  const predictApplications = () => {
    setLoading(true)
    axios.get("predict-applications", {
      params:{
        propName:name,
        floorplan:`${bed}b-${bath}b`
      }
    })
    .then(res => {
      console.log(res.data)
      setPredicted(res.data)
      let predictedData = {
        data: res.data.values,
        labels: res.data.labels
      }
      setPredictionChartData(predictedData)
      setLoading(false)
    })
    .catch(err => {
      console.log("== error is ==", err)
    })
  }

  const handleSwitch = (value) => {
    setToggle(value);
    if (Object.entries(predicted).length === 0) {
      predictApplications()
    }
  }


  const predictionData = {
    labels: predictionChartData.labels,
    datasets: [
    {
      label: 'Demand',
      data: predictionChartData.data,
      borderWidth: 3,
      fill: false,
      pointBackgroundColor: (ctx) => {
        const dataIndex = ctx.dataIndex;
        const dataLength = 16;
        if (dataIndex === dataLength - 2 || dataIndex === dataLength - 3) {
          return '#0000FF'; 
        }
        return '#FF6746'; 
      },
      segment: {
        borderColor: (ctx) => {
          const dataIndex = ctx.p1DataIndex;
          const dataLength = 16;
          if (dataIndex === dataLength - 2 || dataIndex === dataLength - 3) {
            return '#0000FF'; 
          }
          return '#FF6746'; 
        }
      },
    },
  ],
};


const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <small>
    The AI model predicts future demand by analyzing current no. of exposed units, 
    rental rates compared to competitors, reputation based on internet ratings, season, and location. 
    While it provides valuable insights, please note that it may not always make perfect predictions.
    </small>
  </Tooltip>
);


  return (
    <div
      className={`${
        showMore ? null : "w-75 border px-2"
      } dst mx-2 mt-3 shadow-sm py-2 pb-3 `}
      style={{ borderRadius: "14px" }}
    >
      <div style={{
        display:"flex",
        "flexDirection":"row",
        "justifyContent":"space-between"
      }}> 
        {
          toggle? 
          <div style={{
            display:"flex",
            flexDirection:"row",
          }}>
            <h4 className="fw-bold mt-3 mx-2">AI Demand Forecast</h4>
            <OverlayTrigger
              placement="right"
              className="d-flex align-items-center justify-content-center"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
                <Info className="my-auto mx-auto" />
              </OverlayTrigger>
          </div>
          :
          <h4 className="fw-bold mt-3 mx-2">Decision Support Tools</h4>
        }
        
        {/* AI DEMAND FORECAST COMMENTED */}
        {/* {
          !showMore && (
          <Space direction="vertical">
            <Switch checkedChildren="AI Demand Forecast" unCheckedChildren="AI Demand Forecast"  onChange={handleSwitch} />
          </Space> 
        )
        } */}

      </div>
      {showMore
        ? graphsData.map((graph, index) => {
            return (
              <div
                className="d-flex flex-column shadow-sm py-4 mt-3 my-3 justify-content-center px-4 mx-3 border"
                style={{ borderRadius: "14px" }}
                key={index}
              >
                {index === 0 ? (
                  <>
                    <div style={{
                      display:'flex',
                      justifyContent:'space-between'
                    }}>
                    <h4 className="fw-bold">{graphsData[index].title}</h4>
                    {graph.title === "Historical Offered" ? (
                      <ToggleButtonGroup
                      color="primary"
                      value={segmentedValue}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                      >
                        <ToggleButton value="30">30</ToggleButton>
                        <ToggleButton value="60">60</ToggleButton>
                        <ToggleButton value="120">120</ToggleButton>
                        <ToggleButton value="365">365</ToggleButton>
                      </ToggleButtonGroup>
                    ): null}
                    </div>
                    <span>{graphsData[index].subTitle}</span>
                    <>
                      <div className="Legend my-2 d-flex justify-content-end">
                        {graph.datasets.map((data, index) => {
                          return (
                            <LegendBtn
                              label={data.label}
                              subLabel={data.subLabel}
                              color={data.borderColor}
                              key={index}
                            />
                          );
                        })}
                      </div>
                      {graph.title === "Historical Offered" ? (
                        <LineChart
                          ref={ho_ref}
                          resp
                          width="60%"
                          height="20%"
                          data={chartData}
                          optionsP={historicalOptions}
                        /> 
                      ) : (
                        <LineChart
                          resp
                          width="40%"
                          className={
                            index === currentIndex ? "slide active" : "slide"
                          }
                          height="14%"
                          data={graph}
                        />
                      )}
                    </>
                  </>
                ) : (
                  graphsData[1].table
                )}
              </div>
            );
          })
        : 
        (
          !toggle ?
            graphsData.map((graph, index) => {
              return (
                <div key={index}>
                  {index === 0
                    ? index === currentIndex && (
                        <>
                          <div className="Legend my-2 d-flex justify-content-end">
                            {graph.datasets.map((data, index) => {
                              return (
                                <LegendBtn
                                  label={data.label}
                                  subLabel={data.subLabel}
                                  color={data.borderColor}
                                  key={index}
                                />
                              );
                            })}
                          </div>
                          {graph.title === "Historical Offered" ? (
                            <LineChart
                              resp
                              width="40%"
                              className={
                                index === currentIndex ? "slide active" : "slide"
                              }
                              height="14%"
                              data={chartData_30}
                              optionsP={historicalOptions}
                            />
                          ) : (
                            <LineChart
                              resp
                              width="40%"
                              className={
                                index === currentIndex ? "slide active" : "slide"
                              }
                              height="14%"
                              data={graph}
                            />
                          )}
                        </>
                      )
                    : currentIndex === 1
                    ? graph.table
                    : null}
                </div>
              );
            })

            :
            (
              loading ?
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" role="status" className="my-5">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              :
              <Line data={predictionData} />
            )             
        )}
      {showMore || toggle ? (
        <></>
      ) : (
        <div className="d-flex my-2 mt-4">
          <div className="d-flex align-items-center mx-4">
            <OutlinedPrimaryBtn onClick={prevSlide} text="Previous" />
            <SolidPrimaryBtn onClick={nextSlide} text="Next" />
            {/* <button onClick={nextSlide}>Next</button> */}
          </div>
          <div className="d-flex flex-column text-muted text-start justify-content-center">
            <span className="fw-bold">{graphsData[currentIndex].title}</span>
            <span>{graphsData[currentIndex].subTitle}</span>
            <span className="fw-normal">
              Showing {currentIndex + 1} of {graphsData.length}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default DecisionSupportTools;
