import React, { useState, useEffect } from "react";
import "./property.css";
import CompetitorChart from "./Competitors";
import { Spinner } from "react-bootstrap";

const CompWrapper = ({competitorStat}) => {

    const [formattedCompStats, setFormattedCompStats] = useState([]);

    function transformCompData(compData) {
        if (compData !== undefined && Object.keys(compData).length !== 0) {
          let formattedCompData = {
            comp_effective_rent: [],
            effective_rent: [],
            comp_psf: [],
            psf: [],
            dates: [],
            labels: []
        };
    
        const sortedDates = Object.keys(compData).sort((a, b) => new Date(a) - new Date(b));

        sortedDates.forEach(date => {
            const data = compData[date];
            formattedCompData.comp_effective_rent.push(data.comp_effective_rent);
            formattedCompData.effective_rent.push(data.effective_rent);
            formattedCompData.comp_psf.push(data.comp_psf);
            formattedCompData.psf.push(data.psf);
            formattedCompData.dates.push(date);
            formattedCompData.labels.push(date.slice(0, date.lastIndexOf('/')));
        });
        return formattedCompData;
        }
    }
    
    useEffect(() => {
      setFormattedCompStats(transformCompData(competitorStat));
    }, [competitorStat]);
    

    return (
        <>
            {
                formattedCompStats !== undefined && Object.entries(formattedCompStats).length !== 0?
                <CompetitorChart competitors={formattedCompStats}/>
                : 
                <div
                    className="d-flex h-100 justify-content-center"
                    style={{ width: "700px", height: "350px" }}
                >
                    {/* <Spinner animation="border" role="status" className="my-auto">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> */}
                  <h4 className="mt-4"> Competitors Not Found. </h4>
                </div>
            }
        </>
    )
}

export default CompWrapper