/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Pin from "../../assets/MapPin.svg";
import UnitsInfo from "./UnitsInfo";
import Occupancy from "./Occupancy/Occupancy";
import Trend from "./Trend";
import PreLeases from "./Students/PreLeases";
import PropertyExposure from "./PropertyExposure";
import "./property.css";
import { Spinner } from "react-bootstrap";
import ExpirationChart from "./ExpirationChart";
import Marketing from "./Students/Marketing";
import GoalChart from "./Students/GoalsChart";
import CompWrapper from "./CompWrapper";

function Property({
  name,
  propertyData,
  expiryLeases,
  propertyId,
  expirations,
  thresholds,
  trends,
  goalsData,
  isStudentProp,
  preLeases,
  marketingScores,
  renewalOutlook,
  renewalOutlookLabels,
  competitorStat,
}) {
  const [isLoading, setLoading] = useState(false);
  const [formattedTrends, setFormattedTrends] = useState([]);
  const [formattedExpirations, setFormattedExpirations] = useState([]);
  const [formattedThresholds, setFormattedThresholds] = useState([]);
  const [totalPreLeases, setTotalPreLeases] = useState(0)
  const [formattedCompStats, setFormattedCompStats] = useState([]);
  const marketingScore = marketingScores

  const formatExpirations = (data) => {
    if (data) {
      let expirationArr = [];
      let rawMonthLabels = [];
      Object.entries(data).forEach((exp) => {
        rawMonthLabels = [...Object.keys(exp[1])];
        Object.entries(exp[1]).forEach((item, idx) => {
          if (expirationArr.length > 23) {
            expirationArr[idx] += item[1];
          } else expirationArr.push(item[1]);
        });
      });
      setFormattedExpirations({
        data: expirationArr,
        labels: rawMonthLabels,
      });
    }
  };
  const formatThresholds = (data, expirations) => {
    if (data && expirations) {
      const temp = [];
      Object.entries(data).forEach((exp) => {
        const keys = Object.keys(expirations[exp[0]]);
        keys.forEach((key, idx) => {
          if (temp.length > 23) {
            temp[idx] = temp[idx] + exp[1][key];
          } else temp.push(exp[1][key]);
        });
        setFormattedThresholds(temp);
      });
    }
  };

  const formatTrendsAtProperty = (trendsData) => {
    if (trendsData && !isStudentProp) {
      const trendsAtUnits = Object.entries(trendsData).splice(
        1,
        Object.entries(trendsData).length
      );
      const newArray = [];
      trendsAtUnits.forEach((sub) => {
        sub[1].forEach((num, index) => {
          if (newArray[index]) {
            newArray[index] += num;
          } else {
            newArray[index] = num;
          }
        });
      });

      setLoading(false);
      return setFormattedTrends(
        trendsData.total_trend.splice(15, trendsData.total_trend.length)
      );
    }
  };

  const calcPreLeases = (preLeases) => {
    let sum = 0;
    let count = 0;

    for (let key in preLeases) {
      if (preLeases.hasOwnProperty(key)) {
        sum += preLeases[key];
        count++;
      }
    }
    setTotalPreLeases(sum/count)
  }

  useEffect(() => {
    formatTrendsAtProperty(trends);
  }, [trends]);
  useEffect(() => {
    formatExpirations(expirations);
    formatThresholds(thresholds, expirations);
  }, [expirations, thresholds]);

  useEffect(()=> {
    calcPreLeases(preLeases)
  },[preLeases]);

  return (
    <div
      className="property d-flex m-0 mt-4 py-4 shadow border p-3"
      style={{ borderRadius: "14px" }}
    >
      <div className="d-flex flex-column">
        <div className="property-details d-flex">
          <div className="d-flex flex-row">
            <span className="m-0 ms-2 fw-bold fs-4">{name}</span>
            <div className="d-flex mx-3 align-items-center">
              <img src={Pin} alt="pin" />
              <span className="text-muted">{propertyData.address}</span>
            </div>
          </div>
        </div>
        <div className="d-flex tuot mb-3">
          <div className="uot d-flex flex-column justify-content-center">
            <UnitsInfo
              occupancyTarget={propertyData.occupancy_target}
              totalUnits={propertyData.total_units}
            />
            <div className="ot d-flex">
              <Occupancy
                occupiedRate={propertyData.occupancy_rate}
                occupiedUnits={propertyData.occupied_units}
              />
              {!isLoading && formattedTrends ? (
                !isStudentProp?
                <Trend trendsData={formattedTrends} />
                :
                <PreLeases totalPreLeases = {totalPreLeases}  />
              ) : (
                <div className="d-flex w-50 h-100 justify-content-center">
                  <Spinner animation="border" role="status" className="my-auto">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
          </div>
          {
            isStudentProp?
            (
              marketingScore !== undefined && marketingScore !== null && Object.entries(marketingScore).length !== 0?
                <Marketing propertyId = {propertyId} marketingScore = {marketingScore} />
                :
                <div
                  className="d-flex h-100 justify-content-center"
                  style={{ width: "700px", height: "350px" }}
                  >
                  <Spinner animation="border" role="status" className="my-auto">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
            )
            :
            (
              competitorStat !== undefined 
              ?
              <CompWrapper competitorStat={competitorStat}/>
                : 
                <div
                  className="d-flex h-100 justify-content-center"
                  style={{ width: "700px", height: "350px" }}
                >
                  <Spinner animation="border" role="status" className="my-auto">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
            )
          }

        </div>
        <div className="d-flex over-expo">

          {
            isStudentProp ? 
            (
              goalsData[name] !== undefined && goalsData[name]['labels'].length !== 0 ?
                <GoalChart 
                  goalsData = {goalsData}
                  propertyName = {name}
                />
              :
                <div className="d-flex w-100 h-100 justify-content-center">
                  <Spinner animation="border" role="status" className="my-auto">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
            )
            :
            (
              formattedExpirations.length !== 0 &&
              renewalOutlook &&
              renewalOutlookLabels.length !== 0? 
                <ExpirationChart
                  expirations={formattedExpirations}
                  thresholds={formattedThresholds}
                  anticipatedRenConv={expirations.anticipated_ren_conv}
                  renewalOutlook={renewalOutlook}
                  renewalOutlookLabels={renewalOutlookLabels}
                />
              : 
                <div className="d-flex w-100 h-100 justify-content-center">
                  <Spinner animation="border" role="status" className="my-auto">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
            )
          }

          {expiryLeases ? (
            <PropertyExposure expiryLeases={expiryLeases} />
          ) : (
            <div className="d-flex w-100 h-100 justify-content-center">
              <Spinner animation="border" role="status" className="my-auto">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Property;
