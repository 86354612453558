import axios from "../auth/Axios";

export const loadProperties = () => async (dispatch) => {
  let properties;
  try {
    await axios.get("get-occupancy-rates-by-unit-group").then((res) => {
      properties = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadProperties();
    }, 5000);
  }
  dispatch({
    type: "GET_PROPERTIES",
    payload: {
      properties: Object.fromEntries(Object.entries(properties).sort()),
    },
  });
};

export const loadExpirationsUG = () => async (dispatch) => {
  let expirationsUG;
  try {
    await axios.get("get-expiration-by-unit-group").then((res) => {
      expirationsUG = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadExpirationsUG();
    }, 5000);
  }
  dispatch({
    type: "GET_EXPIRATIONSUG",
    payload: {
      expirationsUG: expirationsUG,
    },
  });
};
export const loadExpirationsThresholds = () => async (dispatch) => {
  let thresholds;
  try {
    await axios.get("get-expiration-thresholds-by-unit-group").then((res) => {
      thresholds = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadExpirationsThresholds();
    }, 5000);
  }
  dispatch({
    type: "GET_EXPIRATIONS_THRESHOLDS",
    payload: {
      thresholds: thresholds,
    },
  });
};
export const loadGoalsData = () => async (dispatch) => {
  let goalsData;
  try {
    await axios.get("get-goals-data").then((res) => {
      goalsData = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadGoalsData();
    }, 5000);
  }
  dispatch({
    type: "GET_GOALS_DATA",
    payload: {
      goalsData: goalsData,
    },
  });
};
export const loadPreLeases = () => async (dispatch) => {
  let preLeases;
  try {
    await axios.get("get-pre-leases").then((res) => {
      preLeases = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadPreLeases();
    }, 5000);
  }
  dispatch({
    type: "GET_PRE_LEASES_DATA",
    payload: {
      preLeases: preLeases,
    },
  });
};
export const loadReferenceRent = () => async (dispatch) => {
  let referenceRent = {};

  // try {
  //   await axios.get("get-refrence-rent-by-unit-group").then((res) => {
  //     referenceRent = res.data;

  //   });
  // } catch (error) {
  //   console.log(error);
  //   setTimeout(() => {
  //     loadReferenceRent();
  //   }, 5000);
  // }


  try {
    const response1 = await axios.get("get-refrence-rent-by-unit-group");
    const response2 = await axios.get("get-refrence-rent-by-unit-group-student");
    referenceRent = { ...response1.data, ...response2.data };
  } 
  catch (error) {
    console.log(error);
        setTimeout(() => {
        loadReferenceRent();
      }, 5000);
  }
  dispatch({
    type: "GET_REFERENCE_RENT",
    payload: {
      referenceRent: referenceRent,
    },
  });
};
export const loadExpiringLeases = () => async (dispatch) => {
  let expiringLeases;
  try {
    await axios.get("get-expiring-leases-by-unit-group-v2").then((res) => {
      expiringLeases = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadExpiringLeases();
    }, 5000);
  }
  dispatch({
    type: "GET_EXPIRING_LEASES",
    payload: {
      expiringLeases: expiringLeases,
    },
  });
};
export const loadTrends = () => async (dispatch) => {
  let trends;
  try {
    await axios.get("get-trends-by-unit-group").then((res) => {
      trends = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadTrends();
    }, 5000);
  }
  dispatch({
    type: "GET_TRENDS",
    payload: {
      trends: trends,
    },
  });
};
export const loadReferenceHistory = () => async (dispatch) => {
  let referenceHistory;
  try {
    await axios.get("get-refrence-history-by-unit-group").then((res) => {
      referenceHistory = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadReferenceHistory();
    }, 5000);
  }
  dispatch({
    type: "GET_REFERENCE_HISTORY",
    payload: {
      referenceHistory: referenceHistory,
    },
  });
};


export const loadRenewalOutlookChart = () => async (dispatch) => {
  let renewalOutlook;
  try {
    await axios.get("get-renewal-outlook-chart").then((res) => {
      renewalOutlook = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadRenewalOutlookChart();
    }, 5000);
  }
  dispatch({
    type: "GET_RENEWAL_OUTLOOK",
    payload: {
      renewalOutlook: renewalOutlook,
    },
  });
};

export const loadExpirationsRange = (dateRange) => async (dispatch) => {
  let expirationsRange;
  try {
    if (dateRange) {
      const startDate = `${
        dateRange[0].getMonth() + 1
      }/${dateRange[0].getDate()}/${dateRange[0].getFullYear()}`;
      const endDate = `${
        dateRange[1].getMonth() + 1
      }/${dateRange[1].getDate()}/${dateRange[1].getFullYear()}`;
      await axios
        .get(
          `get-expirations-in-range?start_date=${startDate}&end_date=${endDate}`
        )
        .then((res) => {
          expirationsRange = res.data;
        })
        .catch((err) => {
          console.error(err);
          setTimeout(() => {
            loadExpirationsRange(dateRange);
          }, 5000);
        });
    }
  } catch (error) {
    console.log(error);
  }
  dispatch({
    type: "GET_EXPIRATIONS_RANGE",
    payload: {
      expirationsRange: expirationsRange,
    },
  });
};
export const loadExpiringUnits = (dateRange) => async (dispatch) => {
  let expiringUnits;
  try {
    const startDate = `${
      dateRange[0].getMonth() + 1
    }/${dateRange[0].getDate()}/${dateRange[0].getFullYear()}`;
    const endDate = `${
      dateRange[1].getMonth() + 1
    }/${dateRange[1].getDate()}/${dateRange[1].getFullYear()}`;
    const currentDateTemp = new Date();
    const currentDate = `${
      currentDateTemp.getMonth() + 1
    }/${currentDateTemp.getDate()}/${currentDateTemp.getFullYear()}`;
    await axios
      .get(
        `get-expiring-units?start_date=${startDate}&end_date=${endDate}&date=${currentDate}`
      )
      .then((res) => {
        expiringUnits = res.data;
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => {
          loadExpiringUnits(dateRange);
        }, 5000);
      });
  } catch (error) {
    console.log(error);
  }
  dispatch({
    type: "GET_EXPIRING_UNITS",
    payload: {
      expiringUnits: expiringUnits,
    },
  });
};

export const loadRevenue = () => async (dispatch) => {
  let revenue;
  try {
    await axios.get("/get-revenue-by-unit-group").then((res) => {
      revenue = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadRevenue();
    }, 5000);
  }
  dispatch({
    type: "GET_REVENUE",
    payload: {
      revenue: revenue,
    },
  });
};

export const loadRecentLeasingAverage = () => async (dispatch) => {
  let recentLeasingAverage;
  try {
    await axios.get("/get-recent-leasing-average").then((res) => {
      recentLeasingAverage = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadRecentLeasingAverage();
    }, 5000);
  }
  dispatch({
    type: "GET_RECENT_LEASING_AVERAGE",
    payload: {
      recentLeasingAverage: recentLeasingAverage,
    },
  });
};

export const loadBrains = () => async (dispatch) => {
  let brains;
  try {
    await axios.get("/get-brains").then((res) => {
      brains = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadBrains();
    }, 5000);
  }
  dispatch({
    type: "GET_BRAINS",
    payload: {
      brains: brains,
    },
  });
};

export const loadNewLeases = (propertyId) => async (dispatch) => {
  let newLeases;
  try {
    await axios.get(`get-new-leases`).then((res) => {
      newLeases = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadNewLeases();
    }, 5000);
  }
  dispatch({
    type: "GET_NEW_LEASES",
    payload: {
      newLeases: newLeases,
    },
  });
};
export const loadDailyLeads = () => async (dispatch) => {
  let dailyLeads;
  try {
    await axios.get(`get-daily-leads`).then((res) => {
      dailyLeads = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadDailyLeads();
    }, 5000);
  }
  dispatch({
    type: "GET_DAILY_LEADS_BY_ID",
    payload: {
      dailyLeads: dailyLeads,
    },
  });
};
export const loadLeads = () => async (dispatch) => {
  let leads;
  try {
    await axios.get(`get-leads`).then((res) => {
      leads = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadLeads();
    }, 5000);
  }
  dispatch({
    type: "GET_LEADS_BY_ID",
    payload: {
      leads: leads,
    },
  });
};
export const loadMarketingScore = (propertyId, dateRange=[]) => async (dispatch) => {
  let marketingScore;
  let startDate = null
  let endDate = null

  if (dateRange.length !== 0) {
    startDate = `${
      dateRange[0].getMonth() + 1
    }/${dateRange[0].getDate()}/${dateRange[0].getFullYear()}`;
    endDate = `${
      dateRange[1].getMonth() + 1
    }/${dateRange[1].getDate()}/${dateRange[1].getFullYear()}`;
  }

  try {
    await axios.get(`get-marketing-score?id=${propertyId}&start_date=${startDate}&end_date=${endDate}`).then((res) => {
      marketingScore = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadMarketingScore(propertyId);
    }, 5000);
  }
  dispatch({
    type: "GET_MARKETING_SCORE_BY_ID",
    payload: {
      marketingScore: marketingScore,
    },
  });
};
export const loadCompetitorsStats = () => async (dispatch) => {
  let competitorStats;
  try {
    await axios
      .get(`get-competitor-stats-v2`)
      .then((res) => {
        competitorStats = res.data;
      });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadCompetitorsStats();
    }, 5000);
  }
  dispatch({
    type: "GET_COMPETITOR_STATS_BY_ID",
    payload: {
      competitors: competitorStats,
    },
  });
};
export const loadCompetitorsStatsByProp = () => async (dispatch) => {
  let competitorStatsByProp;
  try {
    await axios
      .get(`get-competitor-stats-by-prop`)
      .then((res) => {
        competitorStatsByProp = res.data;
      });
  } catch (error) {
    setTimeout(() => {
      loadCompetitorsStatsByProp();
    }, 5000);
  }
  dispatch({
    type: "GET_COMPETITOR_STATS_BY_PROP",
    payload: {
      competitorsByProp: competitorStatsByProp,
    },
  });
};
export const loadLeaseCompliance = () => async (dispatch) => {
  let leaseCompliance;
  try {
    await axios
      .get(`get-lease-compliance`)
      .then((res) => {
        leaseCompliance = res.data;
      });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadLeaseCompliance();
    }, 5000);
  }
  dispatch({
    type: "GET_LEASE_COMPLIANCE_BY_ID",
    payload: {
      leaseCompliance: leaseCompliance,
    },
  });
};

//Competitors page
export const loadCompetitorsHistory = () => async (dispatch) => {
  let competitors_history;
  try {
    await axios.get("/competitor_history").then((res) => {
      competitors_history = res.data;
    });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadCompetitorsHistory();
    }, 5000);
  }
  dispatch({
    type: "GET_COMPETITORS_HISTORY",
    payload: {
      competitorsHistory: competitors_history,
    },
  });
};

//REPORTS

export const loadOccupancyReport = (propertyId) => async (dispatch) => {
  let occupancyReport;
  try {
    await axios
      .get(`get-occupancy-report?prop_id=${propertyId}`)
      .then((res) => {
        occupancyReport = res.data;
      });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      loadCompetitorsStats(propertyId);
    }, 5000);
  }
  dispatch({
    type: "GET_OCCUPANCY_REPORT_BY_ID",
    payload: {
      occupancyReport: occupancyReport,
      propertyId: propertyId,
      type: "occupancyReport",
    },
  });
};

export const updateTodaysRent = (name,unitType,todayVal) => (dispatch) => {
  dispatch({
    type: "UPDATE_REFRENCE_RENT",
    payload: {
      name: name,
      unitType: unitType,
      todayVal: todayVal,
    },
  });
}

// PARAMETERS 
export const UpdateParameters = (propertyName,params) => (dispatch) => {
  dispatch({
    type: "UPDATE_PARAMETERS",
    payload: {
      propertyName: propertyName,
      params:params
    },
  });
};